import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilProvider } from "@state/sustentus";
import { configureApi } from "@api/sustentus";
import AuthInitializer from "./AuthInitializer.jsx";
import App from "./App.jsx";
import "./index.css";

configureApi({
  apiUrl: import.meta.env.VITE_API_URL,
});

// Main render
ReactDOM.createRoot(document.getElementById("root")).render(
  <RecoilProvider>
    <BrowserRouter>
      <AuthInitializer>
        <App />
      </AuthInitializer>
    </BrowserRouter>
  </RecoilProvider>
);
