import React from "react";
import __pages_import_0__ from "/src/pages/index.jsx";
const __pages_import_1__ = React.lazy(() => import("/src/pages/notifications.jsx"));
const __pages_import_2__ = React.lazy(() => import("/src/pages/profile.jsx"));
const __pages_import_3__ = React.lazy(() => import("/src/pages/settings.jsx"));
const __pages_import_4__ = React.lazy(() => import("/src/pages/projects/index.jsx"));
const __pages_import_5__ = React.lazy(() => import("/src/pages/projects/[id]/activity.jsx"));
const __pages_import_6__ = React.lazy(() => import("/src/pages/projects/[id]/index.jsx"));
const __pages_import_7__ = React.lazy(() => import("/src/pages/requests/[id].jsx"));
const __pages_import_8__ = React.lazy(() => import("/src/pages/requests/index.jsx"));
const __pages_import_9__ = React.lazy(() => import("/src/pages/requests/new.jsx"));
const __pages_import_10__ = React.lazy(() => import("/src/pages/team/index.jsx"));

const routes = [{"caseSensitive":false,"path":"/","element":React.createElement(__pages_import_0__)},{"caseSensitive":false,"path":"notifications","element":React.createElement(__pages_import_1__)},{"caseSensitive":false,"path":"profile","element":React.createElement(__pages_import_2__)},{"caseSensitive":false,"path":"settings","element":React.createElement(__pages_import_3__)},{"caseSensitive":false,"path":"projects","children":[{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_4__)},{"caseSensitive":false,"path":":id","children":[{"caseSensitive":false,"path":"activity","element":React.createElement(__pages_import_5__)},{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_6__)}]}]},{"caseSensitive":false,"path":"requests","children":[{"caseSensitive":false,"path":":id","element":React.createElement(__pages_import_7__)},{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_8__)},{"caseSensitive":false,"path":"new","element":React.createElement(__pages_import_9__)}]},{"caseSensitive":false,"path":"team","children":[{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_10__)}]}];

export default routes;