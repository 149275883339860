import React from "react";
import { useRoutes } from "react-router-dom";
import routes from "virtual:generated-pages-react";
import { Sidebar, Loading } from "@ui/sustentus";
import { useAccountState } from "@state/sustentus";
import { configureApi } from "@api/sustentus";

const App = () => {
  const { state, signOut } = useAccountState();
  const routing = useRoutes(routes);

  if (!state.isAuth) {
    return <Loading isTrue={!state.isAuth} />;
  }

  configureApi({
    apiUrl: import.meta.env.VITE_API_URL,
    role: "vendor",
    token: state.token,
  });

  return (
    <Sidebar
      isOpen={false}
      menu={[
        {
          name: "Dashboard",
          href: "/",
        },
        {
          name: "New Request",
          href: "/requests/new",
        },
        {
          name: "Requests",
          href: "/requests",
        },
        {
          name: "Projects",
          href: "/projects",
        },
        // {
        //   name: "Finances",
        //   href: "/finances",
        // },
        // {
        //   name: "Team",
        //   href: "/team",
        // },
      ]}
      subMenu={{ title: "", items: [] }}
      user={{
        ...state.user,
        menu: [
          { name: "Your profile", href: "/profile" },
          { name: "Sign out", onClick: signOut },
        ],
      }}
      footer={{
        href: "/settings",
      }}
      children={routing}
    />
  );
};

export default App;
